<app-block-header [isRefreshActive]="isRefreshActive"
                  title="Gestore"
                  icon="user">
</app-block-header>
<app-skeleton [loading]="loading">
  <div class="block read-only-details" *ngIf="manager; else noDati">
    <app-block-values [data]="manager" [mainFields]="['businessName', 'PIva', 'taxCode', 'city']"></app-block-values>
  </div>
</app-skeleton>
<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>
