export const _ = {
  VERSION: '1.269',
  MSxMINUTE: 60000,
  // API_URL:'http://localhost:3000',
  // AUTH_URL: '/auth',
  EMAIL_KEY: '_YF_EMAIL',
  CLIENT_KEY: '_YF_CLIENT',
  AUTHSTATE_KEY: '_YF_AUTHSTATE',
  GEOLOCATION_KEY: '_YF_GEOLOCATION',
  FAVOURITE_CAR_KEY: '_YF_FAVOURITE_CAR',
  SELECTED_FILTERS: '_YF_SELECTED_FILTERS',
  USERS_SEARCH_CRITERIA: '_YF_USERS_SEARCH_CRITERIA',
  INSTALLATON_SEARCH_CRITERIA: '_YF_INSTALLATON_SEARCH_CRITERIA',
  STATION_SEARCH_CRITERIA: '_YF_STATION_SEARCH_CRITERIA',
  PODS_SEARCH_CRITERIA: '_YF_PODS_SEARCH_CRITERIA',
  GROUPS_SEARCH_CRITERIA: '_YF_GROUPS_SEARCH_CRITERIA',
  GATEWAYS_SEARCH_CRITERIA: '_YF_GATEWAYS_SEARCH_CRITERIA',
  SERVICE_PRICE_SETUP_SEARCH_CRITERIA: '_YF_SERVICE_PRICE_SETUP_SEARCH_CRITERIA',
  TARIFFS_SEARCH_CRITERIA: '_YF_TARIFFS_SEARCH_CRITERIA',
  CDR_SEARCH_CRITERIA: '_YF_CDR_SEARCH_CRITERIA',
  SESSION_SEARCH_CRITERIA: '_YF_SESSION_SEARCH_CRITERIA',
  COST_REVENUE_SPLIT_SEARCH_CRITERIA: '_YF_COST_REVENUE_SPLIT_SEARCH_CRITERIA',
  CHAPTERS_SEARCH_CRITERIA: '_YF_CHAPTERS_SEARCH_CRITERIA',
  SECTIONS_SEARCH_CRITERIA: '_YF_SECTIONS_SEARCH_CRITERIA',
  PAYMENT_SEARCH_CRITERIA: '_YF_PAYMENT_SEARCH_CRITERIA',
  LOG_SEARCH_CRITERIA: '_YF_LOG_SEARCH_CRITERIA',
  MAP_SELECTED_INSTALLATION: '_YF_MAP_SELECTED_INSTALLATION',
  MAP_CLUSTER_IDS: '_YF_MAP_CLUSTER_IDS',
  INVOICE_SEARCH_CRITERIA: '_YF_INVOICE_SEARCH_CRITERIA',
  GESTORE_SEARCH_CRITERIA: '_YF_GESTORE_SEARCH_CRITERIA',
  LANG_KEY: '_YF_LANG',
};
