import { Component, EventEmitter, OnInit, Output, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IStation, IPod, ITariff, IUpdatePodDTO, EnergyManagementValues, EnergyManagement } from 'common_library';
import { BehaviorSubject, Observable, Subject, Subscription, debounceTime, switchMap } from 'rxjs';
import { IOptionPod } from 'src/app/pages/pods/pod-new/new-pod.component';
import { StationService } from 'src/app/services/entities/station.service';
import { UserService } from 'src/app/services/entities/user.service';
import { TariffService } from 'src/app/services/entities/tariff.service';
import { PowerPipe } from 'src/app/pipes/power.pipe';

@Component({
  selector: 'app-block-form-pod',
  templateUrl: './block-form-pod.component.html',
  styleUrls: ['./block-form-pod.component.scss']
})
export class BlockFormPodComponent implements OnInit, OnDestroy {
  @Input() pod$ = new BehaviorSubject<IPod>(null);
  optionUsers$ = new BehaviorSubject<Array<IOptionPod>>(null)
  //optionStations$ = new BehaviorSubject<Array<IOptionPod>>(null)
  optionStations$: Observable<IOptionPod[]>
  tariffs$: Observable<ITariff[]>
  isPodOwner = true;

  optionStationsSearch$ = new Subject<string>();
  tariffSearch$ = new Subject<string>();

  podForm: FormGroup;
  initialValue: any;
  @Output() formData = new EventEmitter();
  @Output() cancelButton = new EventEmitter()
  energyManagement: Array<{ id: string, name: string }> = (Object.keys(EnergyManagement) as Array<keyof typeof EnergyManagement>)
    .map((key, index) => {
      return { id: (index + 1).toString(), name: key }
    });
  sub: Subscription;

  constructor(
    private stationService: StationService,
    private userService: UserService,
    private tariffService: TariffService,
    private formBuilder: FormBuilder
  ) { 
    this.podForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required, Validators.minLength(1)]),
      power: new FormControl(null, [Validators.required, Validators.minLength(1)]),
      serial: new FormControl(null, [Validators.required, Validators.minLength(1)]),
      energyMngt: new FormControl('1', [Validators.required]),
      ownerName: new FormControl(),
      masterStationName: new FormControl(),
      tariffName: new FormControl()
    });
  }

  async ngOnInit(): Promise<void> {
    const userRoles = await this.userService.getUserRolesByToken();
    //this.isPodManager = userRoles.includes('ADMIN') ? true : userRoles.includes('POD_MANAGER');
    this.isPodOwner = userRoles.includes('POD_OWNER');
    this.tariffs$ = this.tariffSearch$.pipe(
      debounceTime(500),
      // filter((searchText) => !!searchText),
      switchMap(searchText => {
        if (searchText) { //evito di fare la chiamata che chieda tutti gli elementi: restituisco array vuoto
          return this.getTariffByString(searchText);
        }
        else {
          return [];
        }
      })
    )

    this.optionStations$ = this.optionStationsSearch$.pipe(
      debounceTime(500),
      // filter((searchText) => !!searchText),
      switchMap(async searchText => {
        if (searchText && searchText.length >= 3) { //evito di fare la chiamata che chieda tutti gli elementi: restituisco array vuoto
          const result = await this.stationService.getStationsByStringNotMaster(searchText, this.pod$.getValue()?.masterStationId);
          const optionStations: Array<IOptionPod> = result.map((item) => { return { value: item.id, label: item.name, id: item.id } });
          return optionStations;
        }
        else {
          return [];
        }
      })
    )

    this.podForm.valueChanges.subscribe((value) => {
      const control = this.podForm.controls['masterStationName'];
      if (value.energyMngt !== '1' && !control.hasValidator(Validators.required)) {
        control.addValidators(Validators.required);
        control.markAsDirty();
        if (!value.masterStationName || value.masterStationName.length === 0) {
          control.setErrors({ required: true });
        }
      }
      if (value.energyMngt === '1') {
        control.removeValidators(Validators.required);
        control.setErrors(null);
      }
      control.updateValueAndValidity({ emitEvent: false });
    });

    this.setFieldAccessibility();

    this.sub = this.pod$.subscribe(async (pod) => {
      if (pod) {
        //if(pod.ownerId) pod.owner = await this.userService.getUserById(pod.ownerId);
        if (pod.owner) {
          this.optionUsers$.next([{ value: pod.owner.id, label: `${pod.owner.surname} ${pod.owner.name}`, id: pod.owner.id }]);
        }

        if (pod.masterStationId) {
          let station = await this.stationService.getOneStation(pod.masterStationId);
          if (station) {
            this.onInputAcMasterStation(station.name);
          }
        }

        if (pod.tariffId) {
          let tariff = await this.tariffService.getTariffByIdForPodDetail(pod.tariffId);
          if (tariff) {
            this.onInputTariffStation(tariff.name);
          }
        }
        this.initialValue = {
          name: pod.name,
          power: new PowerPipe().transform(pod.power), // da Watts a Kilowatts
          serial: pod.serial,
          energyMngt: (pod.energyManagement + 1)?.toString(),
          ownerName: pod.ownerId,
          masterStationName: pod.masterStationId,
          tariffName: pod.tariffId
        };

        this.podForm.setValue(this.initialValue);
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  submit() {
    const dto: IUpdatePodDTO = {
      name: this.podForm.get('name').value.trim(),
      serial: this.podForm.get('serial').value.trim(),
      power: parseFloat(this.podForm.get('power').value) * 1000, // da Kilowatts a Watts
      energyManagement: Number(this.podForm.get('energyMngt').value - 1) as EnergyManagementValues,
      ownerId: this.podForm.get('ownerName').value,
      masterStationId: this.podForm.get('masterStationName').value,
      tariffId: this.podForm.get('tariffName').value
    }
    this.formData.emit(dto)
  }

  async onInputAcMasterStation(event) {
    this.optionStationsSearch$.next(event);
  }

  async onInputAcOwnerStation(event) {
    const value = event;
    if (value?.length >= 3) {
      await this.getOwnersByString(value);
    } else {
      this.optionUsers$.next(null);
    }
  }

  async onInputTariffStation(event) {
    this.tariffSearch$.next(event);
  }

  async getStationsByString(stringToFind: string): Promise<IStation[]> {
    return this.stationService.getStationsByStringNotMaster(stringToFind);
  }

  async getOwnersByString(stringToFind: string): Promise<void> {
    if (!!stringToFind && typeof stringToFind === 'string') {
      const result = await this.userService.getUsersByString(stringToFind);
      let optionUsers: Array<IOptionPod> = []
      result.forEach((item) => {
        optionUsers.push({ value: item.id, label: `${item.surname} ${item.name} - ${item.email}`, id: item.id })
      })
      this.optionUsers$.next(optionUsers);
    } else {
      this.optionUsers$.next(null);
    }
  }

  async getTariffByString(stringToFind: string): Promise<ITariff[]> {
    return await this.tariffService.getTariffsByString(stringToFind);
  }

  reset() {
    this.podForm.reset();
    this.podForm.setValue(this.initialValue);
  }

  setFieldAccessibility() {
    if (this.isPodOwner) {
      this.podForm.get('ownerName').disable();
    } else {
      this.podForm.get('ownerName').enable();
    }
  }
}
