import { Component, Input, OnInit } from '@angular/core';
import { IGestore } from 'common_library';
import { GestoreService } from 'src/app/services/entities/gestore.service';

@Component({
  selector: 'app-block-gestori',
  templateUrl: './block-gestori.component.html',
  styleUrls: ['./block-gestori.component.scss']
})
export class BlockGestoriComponent implements OnInit {

  @Input() isRefreshActive: boolean = false;
  @Input() managerId: string;
  @Input() manager: IGestore;
  loading: boolean

  constructor(private gestoreService: GestoreService) { }

  async ngOnInit(): Promise<void> {
    await this.getManager();
  }

  async getManager() {
    this.loading = true;
    if (!this.manager && this.managerId) {
      this.manager = await this.gestoreService.getOneManager(this.managerId);
    }
    this.loading = false;
  }
}
